const lazyForSmallBusinessPageComponent = () => import('@/Pages/En/ForCompanies/Developers.vue');
const lazyForRealEstateManagement = () => import('@/Pages/En/ForCompanies/RealEstateManagement.vue');
const lazyForMarketingPageComponent = () => import('@/Pages/En/ForCompanies/ForMarketing.vue');

export const forCompaniesRoutes = [
  {
    path: '/developers',
    name: 'developers',
    component: lazyForSmallBusinessPageComponent,
    meta: {
      name: 'developers',
    },
  },
  {
    path: '/real-estate-management',
    name: 'real-estate-management',
    component: lazyForRealEstateManagement,
    meta: {
      name: 'real-estate-management',
    },
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: lazyForMarketingPageComponent,
    meta: {
      name: 'marketing',
    },
  },
];
